import Error404 from '@/components/Error404';
import SSpin from '@/components/SSpin';
import AuthContext, { InitialStateType as AuthInitialStateType } from '@/context';
import ContainerView from '@/layouts/ContainerView';
import ModalTrialOver from '@/pages/app/components/ModalTrialOver';
import { IDepartment } from '@/types/department.model';
import { FEATURES, IFeature } from '@/types/features.model';
import { IQuotaCustomTemplate } from '@/types/insights/quota-custom-template.model';
import { IQuotaCustom } from '@/types/insights/quota-custom.model';
import { IQuotaTemplateDetail } from '@/types/insights/quota-template-detail.model';
import { IQuotaTemplate } from '@/types/insights/quota-template.model';
import { IMarketplaceApp } from '@/types/marketplace/marketplace-app.model';
import { IUser } from '@/types/user.model';
import { handleError, isClockingAccount, isCountry, isFeatureEnabled, isNullOrUndefined, now } from '@/utils';
import { message } from 'antd';
import axios from 'axios';
import { Crisp } from 'crisp-sdk-web';
import moment from 'moment';
import React, { lazy, Suspense, useContext, useEffect, useReducer, useState } from 'react';
import ReactConfetti from 'react-confetti';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import Joyride from 'react-joyride';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import Clockings from './clockings';
import Content from './components/Content';
import Header from './components/Header';
import HeaderSmall from './components/HeaderSmall';
import ImpersonateBar from './components/ImpersonateBar';
import ModalAccountBlocked from './components/ModalAccountBlocked';
import ModalHelpVideo from './components/ModalHelpVideo';
import ModalOnboardingFinished from './components/ModalOnboardingFinished';
import PaymentRiskStrip from './components/PaymentRiskStrip';
import TermsModal from './components/TermsModal';
import TrialStrip from './components/TrialStrip';
import WelcomeModal from './components/WelcomeModal';
import AppContext, { getInitialState } from './context';
import DashboardClocking from './dashboard/Clocking';
import DashboardDefault from './dashboard/Default';
import AppDocumentsPage from './documents';
import AppHoursPage from './hours';
import AppHoursClockingValidationPage from './hours/clocking-validation';
import AppLogsPage from './logs';
import reducer from './reducer';
import AppReportsPage from './reports';
import AppSettingsPage from './settings';
import AppTeamPage from './team';

const AppSuperAdminPage = lazy(() => import('./super-admin'));

interface AppLayoutProps {
  className?: string;
  authState: AuthInitialStateType;
  children?: any;
}

const AppLayout: React.FC<AppLayoutProps> = ({ children, className, authState }) => {
  const {
    state: { activeDepartment },
  } = useContext(AppContext);

  message.config({
    top: 35,
  });

  return (
    <div className={className}>
      <Joyride
        continuous={true}
        run={false}
        steps={[
          {
            disableBeacon: true,
            target: '.shyfter-logo',
            content: (
              <React.Fragment>
                <h1>Ok</h1>
                <p>oooo</p>
              </React.Fragment>
            ),
          },
        ]}
        floaterProps={{
          styles: {
            wrapper: {
              zIndex: 1000,
            },
          },
        }}
        styles={{
          options: {
            zIndex: 1000,
          },
        }}
      />
      {authState?.impersonate && <ImpersonateBar authState={authState} />}
      <span className="header-full">
        <Header />
      </span>
      {activeDepartment?.accountType !== 'RGPD' && (
        <span className="header-small">
          <HeaderSmall />
        </span>
      )}
      <Content>
        {/* <UpdateDimonaCertificateStrip /> */}
        {children}
      </Content>
    </div>
  );
};

const AppLayoutStyled = styled(AppLayout)`
  width: 100%;
  min-height: 100vh;

  .header-small {
    display: none;
  }

  @media screen and (max-width: 900px) {
    .header-full {
      display: none;
    }

    .header-small {
      display: inline;
    }
  }
`;

const AppPage: React.FC = () => {
  const { path, url } = useRouteMatch();
  const { state: authContextState } = useContext(AuthContext);
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const { activeDepartment, activeDepartmentId, activeSection, features, loadingFeatures, departments } = state;
  const { i18n, t } = useTranslation(undefined, { useSuspense: false });
  const [loadingFeaturesDone, setLoadingFeaturesDone] = useState(false);
  const [termsAknowledged, setTermsAknowledged] = useState(true);
  const { state: authState, dispatch: authContextDispatch } = useContext(AuthContext);
  const params = new URLSearchParams(window.location.search);
  const pm = params.get('pm');

  const contextValue = {
    state,
    dispatch,
  };

  useEffect(() => {
    let mounted = true;
    const { userDetails, impersonate } = authContextState;

    if (!mounted || !activeDepartment?.id || !userDetails || loadingFeatures) {
      return;
    }

    TagManager.dataLayer({
      dataLayer: {
        userId: userDetails.id,
        username: userDetails.userName,
        email: userDetails.email,
        avatar: userDetails.picture,
        role: activeDepartment.role,
        userLang: userDetails.userLang,
        userhash: userDetails.userhash,
        accountId: activeDepartment.accountId,
        accountType: activeDepartment.accountType,
        departmentId: activeDepartment.id,
        departmentName: activeDepartment.company,
        address: activeDepartment.fullAddress,
        clockings: activeDepartment.clockings,
        countryCode: activeDepartment.countryCode,
        currency: activeDepartment.currency,
        langcode: activeDepartment.userLanguageCode,
        dateCreated: activeDepartment.dateCreated
          ? moment(activeDepartment.dateCreated * 1000).format('DD/MM/YYYY HH:mm:ss')
          : null,
        phone: activeDepartment.phone,
        features,
        partner: activeDepartment.partner,
        paymentRisk: activeDepartment.paymentRisk,
        timezone: activeDepartment.timezone,
        support: activeDepartment.support,
        testAlone: activeDepartment.params?.testAlone,
        api_key: activeDepartment.apiKey,
        impersonate,
      },
      dataLayerName: 'PageDataLayer',
    });

    // if (!impersonate) {
    //   TagManager.dataLayer({
    //     dataLayer: {
    //       event: 'initIntercom',
    //     },
    //     dataLayerName: 'PageDataLayer',
    //   });
    // }

    if (process.env.REACT_APP_CRISP_WEBSITE_ID && !impersonate) {
      Crisp.configure(process.env.REACT_APP_CRISP_WEBSITE_ID, { locale: activeDepartment.userLanguageCode });
      Crisp.chat.hide();

      if (activeDepartment.landphone && activeDepartment.landphone !== '') {
        Crisp.user.setPhone(activeDepartment.landphone);
      }
      if (userDetails.picture && userDetails.picture !== '') {
        Crisp.user.setAvatar(userDetails.picture);
      }
      if (userDetails.email && userDetails.email !== '') {
        Crisp.user.setEmail(userDetails.email);
      }
      if (userDetails.userName && userDetails.userName !== '') {
        Crisp.user.setNickname(userDetails.userName);
      }
      if (activeDepartment.accountCompany && activeDepartment.accountCompany !== '') {
        Crisp.user.setCompany(activeDepartment.accountCompany, {});
      }

      Crisp.session.setData({
        mainId: String(userDetails.id),
        userRecordId: String(activeDepartment.userRecordId),
        username: userDetails.userName || '',
        email: userDetails.email || '',
        avatar: userDetails.picture || '',
        role: activeDepartment.role || '',
        userLang: userDetails.userLang || '',
        userhash: userDetails.userhash || '',
        accountId: String(activeDepartment.accountId),
        accountType: activeDepartment.accountType || '',
        departmentId: String(activeDepartment.id),
        departmentName: activeDepartment.company || '',
        address: activeDepartment.fullAddress || '',
        countryCode: activeDepartment.countryCode || '',
        currency: activeDepartment.currency || '',
        langcode: activeDepartment.userLanguageCode || '',
        dateCreated: activeDepartment.dateCreated
          ? moment(activeDepartment.dateCreated * 1000).format('DD/MM/YYYY HH:mm:ss')
          : '',
        phone: activeDepartment.phone || '',
        partner: activeDepartment.partner || '',
        paymentRisk: activeDepartment.paymentRisk || '',
        timezone: activeDepartment.timezone || '',
        testAlone: activeDepartment.params?.testAlone || '',
        api_key: activeDepartment.apiKey || '',
        super_admin_url: `${process.env.REACT_APP_APP_URL}/app/super-admin/accounts/${activeDepartment.accountId}/${activeDepartment.id}`,
        impersonate: `${process.env.REACT_APP_APP_URL}/app/super-admin/impersonate?email=${userDetails.email}`,
      });
    }

    return () => {
      mounted = false;
    };
  }, [activeDepartment?.id && authContextState.userDetails, loadingFeatures]);

  useEffect(() => {
    if (loadingFeatures || !activeDepartment || !isFeatureEnabled(features, FEATURES.QUOTAS)) return;

    dispatch({
      type: 'SET_LOADING_QUOTA_TEMPLATES',
      payload: true,
    });
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/quotas/quota-templates`, {
        params: {
          departmentId: activeDepartment.id,
        },
      })
      .then(({ data }) => {
        dispatch({
          type: 'SET_QUOTA_TEMPLATES',
          payload: data
            .filter((item: IQuotaTemplate) =>
              !isFeatureEnabled(features, FEATURES.SECTIONS)
                ? item.details.every((detail: IQuotaTemplateDetail) => detail.section_id === null)
                : true,
            )
            .sort((a: IQuotaTemplate, b: IQuotaTemplate) =>
              a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
            ) as IQuotaTemplate[],
        });
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        dispatch({
          type: 'SET_LOADING_QUOTA_TEMPLATES',
          payload: false,
        });
      });
  }, [loadingFeatures, activeDepartmentId]);

  useEffect(() => {
    if (!activeDepartment || loadingFeatures) return;
    dispatch({
      type: 'SET_LOADING_FEATURES',
      payload: true,
    });
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/get-features`, {
        params: {
          departmentId: activeDepartmentId,
        },
      })
      .then(({ data }) => {
        dispatch({
          type: 'SET_FEATURES',
          payload: data as IFeature[],
        });
        if (!isFeatureEnabled(data, FEATURES.SECTIONS)) {
          dispatch({
            type: 'SET_ACTIVE_SECTION',
            payload: undefined,
          });
        }
        localStorage.removeItem('activeSection');
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        dispatch({
          type: 'SET_LOADING_FEATURES',
          payload: false,
        });
        setLoadingFeaturesDone(true);
      });
  }, [activeDepartment]);

  useEffect(() => {
    if (!activeDepartment || features.length == 0) return;
    let mounted = true;
    if (!mounted) return;
    const cancelTokenSource = axios.CancelToken.source();
    if (isFeatureEnabled(features, FEATURES.SCHEDULE)) {
      // WEEKLY_VIEWS
      dispatch({
        type: 'SET_LOADING_WEEKLY_VIEWS',
        payload: true,
      });
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/shifts/views`, {
          params: {
            departmentId: activeDepartment.id,
          },
          cancelToken: cancelTokenSource.token,
        })
        .then((response) => {
          if (mounted) {
            dispatch({
              type: 'SET_WEEKLY_VIEWS',
              payload: response.data,
            });
            dispatch({
              type: 'SET_LOADING_WEEKLY_VIEWS',
              payload: false,
            });
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            message.error(t('WEEKLY_VIEWS.MESSAGE_LOADING_ERROR'));
          }
          if (mounted) {
            dispatch({
              type: 'SET_LOADING_WEEKLY_VIEWS',
              payload: false,
            });
          }
        });
    }

    if (isFeatureEnabled(features, FEATURES.SECTIONS)) {
      // SECTIONS
      dispatch({
        type: 'SET_LOADING_SECTIONS',
        payload: true,
      });
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/sections`, {
          params: {
            departmentId: activeDepartment.id,
          },
          cancelToken: cancelTokenSource.token,
        })
        .then((response) => {
          if (mounted) {
            dispatch({
              type: 'SET_SECTIONS',
              payload: response.data.sections,
            });
            dispatch({
              type: 'SET_LOADING_SECTIONS',
              payload: false,
            });
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            message.error(t('SECTIONS.MESSAGE_LOADING_ERROR'));
          }
          if (mounted) {
            dispatch({
              type: 'SET_LOADING_SECTIONS',
              payload: false,
            });
          }
        });
    }

    if (isFeatureEnabled(features, FEATURES.SKILLS)) {
      // SKILLS
      dispatch({
        type: 'SET_LOADING_SKILLS',
        payload: true,
      });
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/skills`, {
          params: {
            departmentId: activeDepartment.id,
          },
          cancelToken: cancelTokenSource.token,
        })
        .then((response) => {
          if (mounted) {
            dispatch({
              type: 'SET_SKILLS',
              payload: response.data.skills,
            });
            dispatch({
              type: 'SET_LOADING_SKILLS',
              payload: false,
            });
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            console.log(error);
            message.error(t('SKILLS.MESSAGE_LOADING_ERROR'));
          }
          if (mounted) {
            dispatch({
              type: 'SET_LOADING_SKILLS',
              payload: false,
            });
          }
        });
    }

    if (isFeatureEnabled(features, FEATURES.CLOCKING)) {
      // COSTS
      dispatch({
        type: 'SET_LOADING_COSTS',
        payload: true,
      });
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/costs`, {
          params: {
            departmentId: activeDepartment.id,
          },
          cancelToken: cancelTokenSource.token,
        })
        .then(({ data }) => {
          if (mounted) {
            dispatch({
              type: 'SET_COSTS',
              payload: data,
            });
            dispatch({
              type: 'SET_LOADING_COSTS',
              payload: false,
            });
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            console.log(error);
            message.error(t('COSTS.MESSAGE_LOADING_ERROR'));
          }
          if (mounted) {
            dispatch({
              type: 'SET_LOADING_COSTS',
              payload: false,
            });
          }
        });
    }

    if (isFeatureEnabled(features, FEATURES.TASKS)) {
      // TASKS
      dispatch({
        type: 'SET_LOADING_TASKS',
        payload: true,
      });
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/tasks`, {
          params: {
            departmentId: activeDepartment.id,
          },
          cancelToken: cancelTokenSource.token,
        })
        .then((response) => {
          if (mounted) {
            dispatch({
              type: 'SET_TASKS',
              payload: response.data.tasks,
            });
            dispatch({
              type: 'SET_LOADING_TASKS',
              payload: false,
            });
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            console.log(error);
            message.error(t('TASKS.MESSAGE_LOADING_ERROR'));
          }
          if (mounted) {
            dispatch({
              type: 'SET_LOADING_TASKS',
              payload: false,
            });
          }
        });
    }

    if (isFeatureEnabled(features, FEATURES.ATTRIBUTES)) {
      // RESOURCES
      dispatch({
        type: 'SET_LOADING_RESOURCES',
        payload: true,
      });
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/resources`, {
          params: {
            departmentId: activeDepartment.id,
          },
          cancelToken: cancelTokenSource.token,
        })
        .then((response) => {
          if (mounted) {
            dispatch({
              type: 'SET_RESOURCES',
              payload: response.data.resources,
            });
            dispatch({
              type: 'SET_LOADING_RESOURCES',
              payload: false,
            });
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            message.error(t('RESOURCES.MESSAGE_LOADING_ERROR'));
          }
          if (mounted) {
            dispatch({
              type: 'SET_LOADING_RESOURCES',
              payload: false,
            });
          }
        });
    }

    if (isFeatureEnabled(features, FEATURES.SCHEDULE)) {
      // PACKAGES
      dispatch({
        type: 'SET_LOADING_PACKAGES',
        payload: true,
      });
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/packages`, {
          params: {
            departmentId: activeDepartment.id,
          },
          cancelToken: cancelTokenSource.token,
        })
        .then((response) => {
          if (mounted) {
            const { data } = response;
            dispatch({
              type: 'SET_PACKAGES',
              payload: data.packages,
            });
            dispatch({
              type: 'SET_LOADING_PACKAGES',
              payload: false,
            });
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            message.error(t('PACKAGES.MESSAGE_LOADING_ERROR'));
          }
          if (mounted) {
            dispatch({
              type: 'SET_LOADING_PACKAGES',
              payload: false,
            });
          }
        });
    }

    if (isFeatureEnabled(features, FEATURES.SCHEDULE)) {
      // SHORTCUTS
      dispatch({
        type: 'SET_LOADING_SHORTCUTS',
        payload: true,
      });
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/shortcuts`, {
          params: {
            departmentId: activeDepartment.id,
          },
          cancelToken: cancelTokenSource.token,
        })
        .then((response) => {
          if (mounted) {
            const { data } = response;
            dispatch({
              type: 'SET_SHORTCUTS',
              payload: data.shortcuts,
            });
            dispatch({
              type: 'SET_LOADING_SHORTCUTS',
              payload: false,
            });
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            message.error(t('SHORTCUTS.MESSAGE_LOADING_ERROR'));
          }
          if (mounted) {
            dispatch({
              type: 'SET_LOADING_SHORTCUTS',
              payload: false,
            });
          }
        });
    }

    if (isFeatureEnabled(features, FEATURES.HR_PARTNER)) {
      // HR PARTNERS
      dispatch({
        type: 'SET_LOADING_HR_PARTNERS',
        payload: true,
      });
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/operations/hr-partners`, {
          params: {
            departmentId: activeDepartment.id,
          },
          cancelToken: cancelTokenSource.token,
        })
        .then((response) => {
          if (mounted) {
            const { data } = response;
            dispatch({
              type: 'SET_HR_PARTNERS',
              payload: data,
            });
            dispatch({
              type: 'SET_LOADING_HR_PARTNERS',
              payload: false,
            });
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            message.error(t('HR.PARTNERS.MESSAGE_LOADING_ERROR'));
          }
          if (mounted) {
            dispatch({
              type: 'SET_LOADING_HR_PARTNERS',
              payload: false,
            });
          }
        });
    }

    if (isFeatureEnabled(features, FEATURES.HR_PARTNER)) {
      // CUSTOM HR CODES
      dispatch({
        type: 'SET_LOADING_CUSTOM_HR_CODES',
        payload: true,
      });
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/custom-hr-codes`, {
          params: {
            departmentId: activeDepartment.id,
          },
          cancelToken: cancelTokenSource.token,
        })
        .then((response) => {
          if (mounted) {
            const { data } = response;
            dispatch({
              type: 'SET_CUSTOM_HR_CODES',
              payload: data,
            });
            dispatch({
              type: 'SET_LOADING_CUSTOM_HR_CODES',
              payload: false,
            });
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            message.error(t('CUSTOM_HR_CODES.MESSAGE_LOADING_ERROR'));
          }
          if (mounted) {
            dispatch({
              type: 'SET_LOADING_CUSTOM_HR_CODES',
              payload: false,
            });
          }
        });
    }

    if (isFeatureEnabled(features, FEATURES.QUOTAS)) {
      dispatch({
        type: 'SET_LOADING_QUOTA_CUSTOM',
        payload: true,
      });
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/quotas/quota-custom`, {
          params: {
            departmentId: activeDepartmentId,
          },
        })
        .then(({ data }) => {
          dispatch({
            type: 'SET_QUOTA_CUSTOM',
            payload: data as IQuotaCustom[],
          });
        })
        .catch((err) => {
          handleError(err);
        })
        .finally(() => {
          dispatch({
            type: 'SET_LOADING_QUOTA_CUSTOM',
            payload: false,
          });
        });
    }

    if (isFeatureEnabled(features, FEATURES.QUOTAS)) {
      dispatch({
        type: 'SET_LOADING_QUOTA_CUSTOM_TEMPLATES',
        payload: true,
      });
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/quotas/quota-custom-templates`, {
          params: {
            departmentId: activeDepartmentId,
          },
        })
        .then(({ data }) => {
          dispatch({
            type: 'SET_QUOTA_CUSTOM_TEMPLATES',
            payload: data as IQuotaCustomTemplate[],
          });
        })
        .catch((err) => {
          handleError(err);
        })
        .finally(() => {
          dispatch({
            type: 'SET_LOADING_QUOTA_CUSTOM_TEMPLATES',
            payload: false,
          });
        });
    }

    if (isCountry(activeDepartment, ['FR'])) {
      dispatch({
        type: 'SET_LOADING_PAY_PERIODS',
        payload: true,
      });
      axios
        .get(`${process.env.REACT_APP_API_URL}/v3/pay-periods`, {
          params: {
            departmentId: activeDepartmentId,
          },
        })
        .then(({ data }) => {
          dispatch({
            type: 'SET_PAY_PERIODS',
            payload: data,
          });
        })
        .catch((err) => {
          handleError(err);
        })
        .finally(() => {
          dispatch({
            type: 'SET_LOADING_PAY_PERIODS',
            payload: false,
          });
        });
    }

    return () => {
      mounted = false;
    };
  }, [activeDepartment, loadingFeatures]);

  useEffect(() => {
    let mounted = true;
    resetState();
    const cancelTokenSource = axios.CancelToken.source();
    if (!activeDepartment?.id || !mounted) {
      return;
    }

    dispatch({
      type: 'SET_LOADING_MARKETPLACE_APPS',
      payload: true,
    });
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/marketplace`, {
        params: {
          departmentId: activeDepartmentId,
          full: true,
        },
      })
      .then(({ data }) => {
        dispatch({
          type: 'SET_MARKETPLACE_APPS',
          payload: data as IMarketplaceApp[],
        });
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        dispatch({
          type: 'SET_LOADING_MARKETPLACE_APPS',
          payload: false,
        });
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/operations/getBadges`, {
        params: {
          departmentId: activeDepartmentId,
          sectionId: activeSection ? activeSection : undefined,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then(({ data }) => {
        dispatch({
          type: 'SET_BADGES',
          payload: data,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    // USERS
    dispatch({
      type: 'SET_LOADING_USERS',
      payload: true,
    });
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/users`, {
        params: {
          departmentId: activeDepartment.id,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        if (mounted) {
          const { data } = response;
          if (authState.userDetails) {
            const loggedUser = data.users.find((user: IUser) => user.mainId === authState.userDetails?.id);
            axios
              .get(`${process.env.REACT_APP_API_URL}/v3/users/${loggedUser?.recordId}/params`, {
                params: {
                  departmentId: activeDepartment.id,
                },
              })
              .then((res) => {
                const { data } = res;
                authContextDispatch({
                  type: 'SET_USER_DETAILS',
                  payload: { ...authState.userDetails!, params: data },
                });
              })
              .catch((error) => {
                console.log(error);
              });
          }
          dispatch({
            type: 'SET_USERS',
            payload: data.users,
          });
          dispatch({
            type: 'SET_USERS_FIELDS',
            payload: data.fields,
          });
          dispatch({
            type: 'SET_LOADING_USERS',
            payload: false,
          });
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          message.error(t('USERS.MESSAGE_LOADING_ERROR'));
        }
        if (mounted) {
          dispatch({
            type: 'SET_LOADING_USERS',
            payload: false,
          });
        }
      });

    // DAYOFFS
    dispatch({
      type: 'SET_LOADING_DAYOFFS',
      payload: true,
    });
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/dayoffs`, {
        params: {
          departmentId: activeDepartment.id,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        if (mounted) {
          const { data } = response;
          dispatch({
            type: 'SET_DAYOFFS',
            payload: data.dayoffs,
          });
          dispatch({
            type: 'SET_DAYOFFS_TYPES',
            payload: data.types,
          });
          dispatch({
            type: 'SET_LOADING_DAYOFFS',
            payload: false,
          });
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          message.error(t('DAYOFFS.MESSAGE_LOADING_ERROR'));
        }
        if (mounted) {
          dispatch({
            type: 'SET_LOADING_DAYOFFS',
            payload: false,
          });
        }
      });

    // USER-CATEGORIES
    dispatch({
      type: 'SET_LOADING_USER_CATEGORIES',
      payload: true,
    });
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/user-categories`, {
        params: {
          departmentId: activeDepartment.id,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then(({ data }) => {
        if (mounted) {
          dispatch({
            type: 'SET_USER_CATEGORIES',
            payload: data.userCategories,
          });
          dispatch({
            type: 'SET_LOADING_USER_CATEGORIES',
            payload: false,
          });
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          message.error(t('USER_CATEGORIES.MESSAGE_LOADING_ERROR'));
        }
        if (mounted) {
          dispatch({
            type: 'SET_LOADING_USER_CATEGORIES',
            payload: false,
          });
        }
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/operations/get-help`, {
        params: {
          departmentId: activeDepartment.id,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        (window as any).onBoardVideoList = response.data;
      })
      .catch((error) => {
        (window as any).onBoardVideoList = {};
        if (axios.isCancel(error)) {
        }
      });

    dispatch({
      type: 'SET_LOADING_HR_FIELDS',
      payload: true,
    });
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/ay/fields`, {
        params: {
          departmentId: activeDepartment.id,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then(({ data }) => {
        dispatch({
          type: 'SET_HR_FIELDS',
          payload: data,
        });
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        dispatch({
          type: 'SET_LOADING_HR_FIELDS',
          payload: false,
        });
      });

    dispatch({
      type: 'SET_LOADING_PC_CATEGORIES',
      payload: true,
    });
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/ay/pc-categories`, {
        params: {
          departmentId: activeDepartment.id,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then(({ data }) => {
        dispatch({
          type: 'SET_PC_CATEGORIES',
          payload: data,
        });
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        dispatch({
          type: 'SET_LOADING_PC_CATEGORIES',
          payload: false,
        });
      });

    dispatch({
      type: 'SET_LOADING_AY_WORKER_TYPES',
      payload: true,
    });
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/ay/worker-type`, {
        params: {
          departmentId: activeDepartment.id,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then(({ data }) => {
        dispatch({
          type: 'SET_AY_WORKER_TYPES',
          payload: data,
        });
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        dispatch({
          type: 'SET_LOADING_AY_WORKER_TYPES',
          payload: false,
        });
      });

    return () => {
      mounted = false;
    };
  }, [activeDepartment]);

  const resetState = () => {
    dispatch({
      type: 'SET_SECTIONS',
      payload: [],
    });
    dispatch({
      type: 'SET_SKILLS',
      payload: [],
    });
    dispatch({
      type: 'SET_TASKS',
      payload: [],
    });
    dispatch({
      type: 'SET_RESOURCES',
      payload: [],
    });
    dispatch({
      type: 'SET_USERS',
      payload: [],
    });
    dispatch({
      type: 'SET_DAYOFFS',
      payload: [],
    });
    dispatch({
      type: 'SET_PACKAGES',
      payload: [],
    });
    dispatch({
      type: 'SET_SHORTCUTS',
      payload: [],
    });
    dispatch({
      type: 'SET_USER_CATEGORIES',
      payload: [],
    });
  };

  useEffect(() => {
    if (!activeDepartment) return;
    if (activeDepartment.accountType == 'TRIAL') return;
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/legal/terms-aknowledge`, {
        cancelToken: cancelTokenSource.token,
      })
      .then(({ data }) => {
        setTermsAknowledged(data.aknowledged);
      })
      .catch((error) => {
        handleError(error);
      });
  }, [activeDepartment]);

  useEffect(() => {
    let mounted = true;
    const cancelTokenSource = axios.CancelToken.source();
    dispatch({
      type: 'SET_DEPARTMENTS',
      payload: [],
    });
    dispatch({
      type: 'SET_LOADING_DEPARTMENTS',
      payload: true,
    });
    axios
      .get(`${process.env.REACT_APP_API_URL}/v3/departments`, {
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        if (mounted) {
          const departments = response.data.departments as IDepartment[];
          dispatch({
            type: 'SET_DEPARTMENTS',
            payload: departments,
          });
          dispatch({
            type: 'SET_LOADING_DEPARTMENTS',
            payload: false,
          });
          const impersonateDepartmentId = sessionStorage.getItem('impersonateDepartmentId');
          const previousDepartmentId = localStorage.getItem('activeDepartmentId');
          if (departments && departments.length && !activeDepartmentId) {
            const department = departments.find((department) =>
              impersonateDepartmentId && impersonateDepartmentId !== ''
                ? department.id == impersonateDepartmentId
                : previousDepartmentId &&
                  previousDepartmentId != '' &&
                  departments.map((department) => department.id).includes(previousDepartmentId)
                ? department.id == previousDepartmentId
                : department.role !== 'USER',
            );
            dispatch({
              type: 'SET_ACTIVE_DEPARTMENT',
              payload: department?.id,
            });
            authContextDispatch({
              type: 'SET_TRIAL_END_DATE',
              payload: department?.trialEndDate || null,
            });
            if (department && department.accountType == 'BLOCKED') {
              if (pm && pm == 'true') {
                authContextDispatch({
                  type: 'SET_BLOCKED',
                  payload: false,
                });
                dispatch({
                  type: 'SET_DEPARTMENTS',
                  payload: departments.map((department) =>
                    department.id == department.id ? { ...department, accountType: 'PAID' } : department,
                  ),
                });
              } else {
                authContextDispatch({
                  type: 'SET_BLOCKED',
                  payload: true,
                });
                dispatch({
                  type: 'SET_DEPARTMENTS',
                  payload: departments.map((department) =>
                    department.id == department.id ? { ...department, accountType: 'BLOCKED' } : department,
                  ),
                });
              }
            }
          } else {
            const department = departments.find((department) =>
              impersonateDepartmentId && impersonateDepartmentId !== ''
                ? department.id == impersonateDepartmentId
                : previousDepartmentId &&
                  previousDepartmentId != '' &&
                  departments.map((department) => department.id).includes(previousDepartmentId)
                ? department.id == previousDepartmentId
                : department.id === activeDepartmentId,
            );
            if (department) {
              dispatch({
                type: 'SET_ACTIVE_DEPARTMENT',
                payload: department?.id,
              });
              authContextDispatch({
                type: 'SET_TRIAL_END_DATE',
                payload: department?.trialEndDate || null,
              });
              if (department && department.accountType == 'BLOCKED') {
                if (pm && pm == 'true') {
                  authContextDispatch({
                    type: 'SET_BLOCKED',
                    payload: false,
                  });
                  dispatch({
                    type: 'SET_DEPARTMENTS',
                    payload: departments.map((department) =>
                      department.id == department.id ? { ...department, accountType: 'PAID' } : department,
                    ),
                  });
                } else {
                  authContextDispatch({
                    type: 'SET_BLOCKED',
                    payload: true,
                  });
                  dispatch({
                    type: 'SET_DEPARTMENTS',
                    payload: departments.map((department) =>
                      department.id == department.id ? { ...department, accountType: 'BLOCKED' } : department,
                    ),
                  });
                }
              }
            } else {
              const department = departments.find((department) =>
                previousDepartmentId &&
                previousDepartmentId != '' &&
                departments.map((department) => department.id).includes(previousDepartmentId)
                  ? department.id == previousDepartmentId
                  : department.role !== 'USER',
              );
              dispatch({
                type: 'SET_ACTIVE_DEPARTMENT',
                payload: department?.id,
              });
              authContextDispatch({
                type: 'SET_TRIAL_END_DATE',
                payload: department?.trialEndDate || null,
              });
              if (department && department.accountType == 'BLOCKED') {
                if (pm && pm == 'true') {
                  authContextDispatch({
                    type: 'SET_BLOCKED',
                    payload: false,
                  });
                  dispatch({
                    type: 'SET_DEPARTMENTS',
                    payload: departments.map((department) =>
                      department.id == department.id ? { ...department, accountType: 'PAID' } : department,
                    ),
                  });
                } else {
                  authContextDispatch({
                    type: 'SET_BLOCKED',
                    payload: true,
                  });
                  dispatch({
                    type: 'SET_DEPARTMENTS',
                    payload: departments.map((department) =>
                      department.id == department.id ? { ...department, accountType: 'BLOCKED' } : department,
                    ),
                  });
                }
              }
            }
          }
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          message.error(t('DEPARTMENTS.MESSAGE_LOADING_ERROR'));
        }
        if (mounted) {
          dispatch({
            type: 'SET_LOADING_DEPARTMENTS',
            payload: false,
          });
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
    // eslint-disable-next-line
  }, []);

  const showModalTrialOver = !isNullOrUndefined(authState?.trialEndDate) && authState.trialEndDate! < moment().unix();
  const showModalAccountBlocked = authState?.blocked;
  const showModalOnboardingFinished = activeDepartment?.accountType == 'ONBOARDING FINISHED';
  const { pathname, search } = useLocation();
  const [initConfetti, setInitConfetti] = useState(false);
  const [showConfetti, setShowConfetti] = useState(true);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  useEffect(() => {
    let mounted = true;
    const cancelTokenSource = axios.CancelToken.source();
    const params = new URLSearchParams(search);
    const subscription = params.get('subscription');

    if (activeDepartment && mounted) {
      if (pathname == '/app/dashboard' && subscription && subscription == 'true') {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/v3/operations/chargeBeeSubscription`,
            {
              departmentId: activeDepartment?.id,
            },
            {
              cancelToken: cancelTokenSource.token,
            },
          )
          .then(({ data }) => {
            if (data.results == 'created') {
              setShowWelcomeModal(true);
              setInitConfetti(true);
              setShowConfetti(true);

              setTimeout(() => {
                setShowConfetti(false);
              }, 2000);

              setTimeout(() => {
                setInitConfetti(false);
              }, 10000);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [activeDepartment, search, pathname]);

  return (
    <AppContext.Provider value={contextValue}>
      {!termsAknowledged && !authState.impersonate && <TermsModal />}
      {activeDepartment && (
        <AppLayoutStyled authState={authState}>
          <Suspense fallback={<SSpin spinning={true} style={{ width: '100%', height: '100vh' }} />}>
            {showWelcomeModal && <WelcomeModal visible={showWelcomeModal} setVisible={setShowWelcomeModal} />}
            {initConfetti && <ReactConfetti recycle={showConfetti} numberOfPieces={500} style={{ zIndex: 1000000 }} />}
            {loadingFeaturesDone ? (
              <Switch>
                <Route
                  exact
                  path={`${path}`}
                  render={() => (
                    <Redirect
                      to={
                        activeDepartment.accountType == 'RGPD'
                          ? `${url}/reports/timesheets`
                          : isClockingAccount(features)
                          ? `${url}/clockings/${now().format('YYYY-MM-DD')}`
                          : !isFeatureEnabled(features, FEATURES.SCHEDULE) &&
                            !isFeatureEnabled(features, FEATURES.CLOCKING)
                          ? `${url}/team/collaborators/all`
                          : `${url}/dashboard`
                      }
                    />
                  )}
                />
                {activeDepartment.accountType !== 'RGPD' && (
                  <Route exact path={`${path}/dashboard`}>
                    {isClockingAccount(features) || activeDepartment?.params?.clockingDashboard ? (
                      <DashboardClocking
                        showModalTrialOver={
                          showModalTrialOver && showModalAccountBlocked && showModalOnboardingFinished
                        }
                      />
                    ) : (
                      <DashboardDefault
                        showModalTrialOver={
                          showModalTrialOver && showModalAccountBlocked && showModalOnboardingFinished
                        }
                      />
                    )}
                  </Route>
                )}
                {activeDepartment.accountType !== 'RGPD' &&
                  ['ADMIN', 'PLANNING', 'HR'].includes(activeDepartment?.role || '') &&
                  isFeatureEnabled(features, FEATURES.CLOCKING) &&
                  activeDepartment.access?.clockings &&
                  !activeDepartment?.clockinParams?.approveFreeclockin && (
                    <Route exact path={`${path}/clockings/clocking-validation`}>
                      <ContainerView>
                        <AppHoursClockingValidationPage />
                      </ContainerView>
                    </Route>
                  )}
                {activeDepartment.accountType !== 'RGPD' &&
                  ['ADMIN', 'PLANNING', 'HR'].includes(activeDepartment?.role || '') &&
                  isFeatureEnabled(features, FEATURES.CLOCKING) &&
                  activeDepartment.access?.clockings && (
                    <Route exact path={`${path}/clockings/:start`}>
                      <Clockings />
                    </Route>
                  )}
                {activeDepartment.accountType !== 'RGPD' &&
                  ['ADMIN', 'PLANNING', 'HR'].includes(activeDepartment?.role || '') &&
                  activeDepartment?.access?.shifts && (
                    <Route path={`${path}/hours`}>
                      <AppHoursPage access={activeDepartment?.access} department={activeDepartment} />
                    </Route>
                  )}
                {activeDepartment.accountType !== 'RGPD' &&
                  ['ADMIN', 'PLANNING', 'HR'].includes(activeDepartment?.role || '') && (
                    <Route path={`${path}/team`}>
                      <AppTeamPage userSubscription={activeDepartment?.userSubscription} resourceSubscription={true} />
                    </Route>
                  )}
                {['ADMIN', 'PLANNING', 'HR'].includes(activeDepartment?.role || '') &&
                  activeDepartment?.access?.reports && (
                    <Route path={`${path}/reports`}>
                      <AppReportsPage department={activeDepartment} />
                    </Route>
                  )}
                {activeDepartment.accountType !== 'RGPD' && (
                  <Route path={`${path}/settings`}>
                    <AppSettingsPage />
                  </Route>
                )}
                {activeDepartment.accountType !== 'RGPD' &&
                  ((isFeatureEnabled(features, FEATURES.CONTRACTS) && activeDepartment?.access?.documents) ||
                    !!activeDepartment?.weeklyReports?.length ||
                    (isFeatureEnabled(features, FEATURES.DIMONA) &&
                      activeDepartment?.params?.dimona &&
                      activeDepartment?.access?.dimona)) && (
                    <Route path={`${path}/documents`}>
                      <AppDocumentsPage department={activeDepartment} />
                    </Route>
                  )}
                {activeDepartment.accountType !== 'RGPD' && activeDepartment?.role === 'ADMIN' && (
                  <Route path={`${path}/logs`}>
                    <AppLogsPage />
                  </Route>
                )}
                {activeDepartment.accountType !== 'RGPD' &&
                  ['SUPER-ADMIN', 'SSA'].includes(authState.userDetails?.role || '') && (
                    <Route path={`${path}/super-admin`}>
                      <AppSuperAdminPage
                        role={authState.userDetails?.role!}
                        level={authState.userDetails?.customLevel}
                      />
                    </Route>
                  )}
                <Route component={() => <Error404 />} />
              </Switch>
            ) : (
              <SSpin spinning={true} style={{ width: '100%', height: '100vh' }} />
            )}
          </Suspense>
        </AppLayoutStyled>
      )}
      {!showWelcomeModal && authState?.trialEndDate && authState.trialEndDate! > moment().unix() && (
        <TrialStrip date={authState.trialEndDate!} />
      )}
      {(!!activeDepartment?.paymentRisk ||
        activeDepartment?.accountType == 'FREE-CLOCKING' ||
        activeDepartment?.accountType == 'ONBOARDING' ||
        activeDepartment?.accountType == 'ONBOARDING FINISHED') &&
        !showWelcomeModal && <PaymentRiskStrip department={activeDepartment} />}
      {!showWelcomeModal && <ModalTrialOver visible={showModalTrialOver} />}
      {!showWelcomeModal && authState.userDetails && <ModalAccountBlocked visible={authState?.blocked} />}
      {!showWelcomeModal && (
        <ModalOnboardingFinished visible={activeDepartment?.accountType == 'ONBOARDING FINISHED'} />
      )}
      <ModalHelpVideo />
      {!activeDepartment && <SSpin spinning={true} style={{ width: '100%', height: '100vh' }} />}
    </AppContext.Provider>
  );
};

export default AppPage;
